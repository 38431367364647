.sideBorders {
  @apply relative;
}
.sideBorders:before,
.sideBorders:after {
  content: " ";
  @apply absolute w-0 top-0 bottom-52 md:border-opacityGray-300;
}

.sideBorders:before {
  @apply left-0 border-l;
}

.sideBorders:after {
  @apply right-0 border-r;
}
