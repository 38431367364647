.mask {
  -webkit-mask-image: url("/assets/graphics/header-mobile-mask.background.svg");
  mask-image: url("/assets/graphics/header-mobile-mask.background.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom;
  mask-position: bottom;
}

@media all and (min-width: 1024px) {
  .mask {
    mask-image: url("/assets/graphics/header-mask-center.background.svg");
    -webkit-mask-image: url("/assets/graphics/header-mask-center.background.svg");
  }
}
