.light {
  background: #f0f0f0;
}

.night {
  background: #2c2c2c;
}

.endemic {
  background: linear-gradient(180deg, #d60a51 0%, #f9b233 100%);
}
