@media screen and (min-width: 1024px) {
  .bgMask {
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-position: bottom left;
    -webkit-mask-image: url("../../../../../assets/graphics/backgrounds/featured-post-mask.background.svg");
    mask-image: url("../../../../../assets/graphics/backgrounds/featured-post-mask.background.svg");
  }
}
